<template>
  <div
    v-if="imageUploadFolder"
    class="ScreeningsUpdateFloorPlans">
    <div class="InnerPage">
      <div class="TitleWrap">
        <span class="PageTitle">{{ mixWB('FLOOR_PLANS') }}</span>
        <SingleUnitSelectedText />
      </div>

      <CloseButton />

      <div
        class="ImageUploadWrap"
        :class="{
          FloorPlanSkipped: unit.hasFloorPlanSkipped()
        }"
        v-for="unit in mutatedSelectedUnits"
        :key="unit.id" >
        <ImageUploadForm
          :basePath="getBasePath(unit.id)"
          :groupName="unit.id"
          :minSize="1200"
          :place="unit.getTitle()"
          :requiredNumber="1"
          :maxNumber="1"
          :title="unit.getFullTitle()"
          makePortrait
          :onCustomConfirmDelete="() => onCustomConfirmDelete(unit)"
          @image-list-update="onImageListUpdate">
          <div
            v-if="unit.hasFloorPlanSkipped()"
            class="SkippedText">
            ✓ {{ mixWB('SKIPPED') }}
          </div>
          <Button
            class="SkipButton"
            v-if="unit.canHaveFloorPlanSkipped()"
            outlined
            :fullWidth="false"
            :text="!unit.hasFloorPlanSkipped() ? mixWB('SKIP') : mixWB('UPLOAD_FLOOR_PLAN')"
            @button-click="onFloorPlanSkippedClick(unit.id)" />
        </ImageUploadForm>
      </div>

      <NextStepLine
        :isDisabled="!floorPlanStatus.isCompleted"
        :showNeedCompleteText="!floorPlanStatus.isCompleted"
        showTopBorder />

      <div
        class="CadastralMapImageWrap"
        v-if="cadastralMapImage">
        <span class="Title">{{ mixWB('CADASTRAL_MAP') }}</span>
        <MultiImage
          :image="cadastralMapImage"
          transformation="square_400" />
      </div>
    </div>
  </div>
</template>

<script>
import CloseButton from '@/components/ScreeningItems/CloseButton.vue'
import MultiImage from '@/components/Images/MultiImage.vue'
import ImageUploadForm from '@/components/Forms/ImageUploadForm.vue'
import Button from '@/components/Buttons/Button.vue'
import EventBus from '@/EventBus'
import { mapGetters } from 'vuex'
import SingleUnitSelectedText from '@/components/SingleUnitSelectedText.vue'
import NextStepLine from '@/components/ScreeningItems/NextStepLine.vue'

export default {
  name: 'ScreeningsUpdateFloorPlans',
  computed: {
    ...mapGetters([
      'addressImages',
      'selectedUnits',
      'currentScreening',
      'screeningSamples',
      'floorPlanStatus',
      'imageUploadFolder',
      'currentScreeningSelectedFilterUnitID',
      'isDemoUser',
    ]),
    mutatedSelectedUnits() {
      if (this.currentScreeningSelectedFilterUnitID) {
        return this.selectedUnits.filter(
          (x) => x.id === this.currentScreeningSelectedFilterUnitID,
        )
      }
      return this.selectedUnits
    },
    cadastralMapImage() {
      if (!this.isDemoUser && this.addressImages?.overview?.cadastralMap?.length) {
        return this.addressImages.overview.cadastralMap[
          this.addressImages.overview.cadastralMap.length - 1
        ]
      }

      return false
    },
  },
  methods: {
    getBasePath(unitID) {
      return `${ this.imageUploadFolder }AddressImages/Units/${ unitID }/`
    },
    setImagesOnLoad() {
      const waitForData = setInterval(() => {
        if (!this.addressImages === null) {
          return
        }

        if (!this.selectedUnits.length) {
          return
        }

        clearInterval(waitForData)

        if (this.addressImages?.units) {
          this.selectedUnits.forEach((unit) => {
            const unitImages = this.addressImages.units[unit.id]

            if (unitImages && unitImages.floorPlans) {
              EventBus.$emit('set-images-on-load', {
                imageList: [...unitImages.floorPlans],
                groupName: unit.id,
              })
            }
          })
        }
      }, 100)
    },
    onCustomConfirmDelete(unit) {
      // Check if we have some markings positioned on the unit's floor plan
      if (this.screeningSamples.some((sample) => sample.floorPlanUnit === unit.id)) {
        return window.confirm(this.mixWB('ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE_FLOORPLAN_WITH_SAMPLES'))
      }
      return window.confirm(this.mixWB('ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE_IMAGE'))
    },
    onImageListUpdate({ imageList, groupName: place }) {
      // Detect if the image was removed and ensure evnetually sample markings are removed.
      if (this.addressImages?.units?.[place]?.floorPlans?.length && !imageList.length) {
        this.screeningSamples.forEach((sample) => {
          if (sample.floorPlanUnit !== place) {
            return
          }
          sample.resetFloorPlan()
          sample.status = 'not-done'

          this.$store.dispatch('setScreeningSample', { sample })
        })
      }

      this.$store.dispatch('setAddressImages', {
        imageList,
        place,
        imageType: 'floorPlans',
      })
    },
    onFloorPlanSkippedClick(unitID) {
      const unit = this.selectedUnits.find((unit) => unit.id === unitID)

      unit.isFloorPlanSkipped = !unit.isFloorPlanSkipped
      this.$store.dispatch('saveScreeningUnits', { combinedList: [unit], updateSelectedUnits: false })
    },
  },
  components: {
    CloseButton,
    ImageUploadForm,
    MultiImage,
    Button,
    SingleUnitSelectedText,
    NextStepLine,
  },
  created() {
    this.setImagesOnLoad()
  },
}
</script>

<style lang="stylus" scoped>
  .ScreeningsUpdateFloorPlans
    page-wrap-outer-1()

  .InnerPage
    page-wrap-inner-1()

  .TitleWrap
    margin-bottom 20px
    .PageTitle
      display block
      font-size 1.125rem
      font-weight bold
      text-transform uppercase

  .ImageUploadWrap
    margin-bottom 20px
    >>> .ImageList
      align-items flex-start

    .SkipButton
      margin-left auto
      width auto
      margin-bottom 0
    &.FloorPlanSkipped
      >>> .UploadButton
        display none
  .SkippedText
    align-self center
    padding-left 5px
    color $color_grey
    font-style italic

  .CadastralMapImageWrap
    margin-top 20px
    .Title
      display block
      font-size 0.875rem
      padding-bottom 2px
</style>
